import React from 'react'
import styled from 'styled-components';
import CantonProfilePreview from '../GeneralUse/Canton/CantonProfilePreview'
import OrganisationProfile from '../GeneralUse/Canton/CantonProfile';
import { importAllImagesFolder } from '../../Assets/UtilityFunctions';
import { Link } from 'react-router-dom';
import { BigText, BLUE, Button, PRIMARY } from '../../Assets/Styles';
import translate from '../../i18n/messages/translate';
import { useEffect } from 'react';
import { getAllDocuments } from '../../firebase/DatabaseFunctions';
import Loading from '../GeneralUse/Loading';
import { database, storage } from "../../firebase/config";
//import image from "../../Assets/Images/CantonFlags/PresentCantons/Aargau.png"
//import baselStadt from "../../Assets/Images/CantonFlags/PresentCantons/BaselStadt.png"

var uniqid = require("uniqid")

const images = importAllImagesFolder(require.context('../../Assets/Images/CantonFlags/PresentCantons', false, /\.(png)$/));
const keys = Object.keys(images);


const Container = styled.div`
    margin-top: 15%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vh;
    @media (orientation: landscape){
      margin-top: 7%;
      margin-bottom: 15vh;

    }
    `

const LinkContainer = styled.div`
width: 90%;
position: relative;
height: fit-content;
margin: auto;
background: ${BLUE};
padding: 5%;
padding-top: 0;
box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
margin-bottom: 60px;


@media (orientation: landscape) and (min-width: 768px){
    width: 10vw;
    padding: 2%;
    padding-top: 0;

}
`

/* 

=== USELESS CODE?
 */


const addresses = ["Lochmattstrasse 8 5417 Untersiggenthal", "", "", "6 Clos Mallet du Pan 1208 Genève", "Hornsbergstrasse 19 8248 Uhwiesen", "Case postale 7781 1002 Lausanne", "8400 Winterthur", "Langstrasse 136 CH-8004 Zürich"]
const links = ["/regions", "https://www.facebook.com/nhg.regionbasel", "https://www.nhg-bern.ch/", "/regions", "/regions", "/regionsd", "https://www.nhg-winterthur.ch", "https://www.nhg-zuerich.ch"]
const presidents = ["Norbert Stichert", "Tabea R. Weber", "Alec von Graffenried", "Sebastian Aeschbach", "Daniel Landolt", "Régis Ducrey", "Hans-Jakob Mosimann", "Christian Lüthi"]

let finalArray = []
for (let i = 0; i < 8; i++) {
  finalArray.push({ name: keys[i], link: links[i], address: addresses[i], president: presidents[i] })
}


const Cantons = ({ limit }) => {
  const [cantons, setCantons] = React.useState([])
  const [loading, setLoading] = React.useState(true)



  useEffect(() => {
    let documents = []
    database.collection("cantonOrganisations")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data()) { documents.push({ ...doc.data() }) }
        });
        setCantons(previous => documents)
        setLoading(prevoius => false)
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      })
  }, [])
  const limitArray = cantons.slice(0, limit)




  return (
    loading ?
      <Loading></Loading>
      :
      <div >
        {limit ? <BigText style={{ color: BLUE }}>{translate("regions")}</BigText> : null}
        <Container>
          {limit ?
            <>
              {limitArray.map((element, index) => <CantonProfilePreview key={uniqid()} name={element.name} flagImage={element.flagImage} link={element.link} address={element.address} president={element.president} presidentWording={element.presidentWording} cantonId={element.id} cantonUrl={element.cantonUrl}></CantonProfilePreview>)}
              <Link style={{ width: "100%" }} to={`/region`}><Button shadow color={BLUE}>{translate("all regions")}</Button></Link>
            </>
            :
            cantons.map((element) => <CantonProfilePreview key={uniqid()} name={element.name} flagImage={element.flagImage} link={element.link} address={element.address} president={element.president} presidentWording={element.presidentWording} cantonId={element.id} cantonUrl={element.cantonUrl}></CantonProfilePreview>)
          }

        </Container>
      </div>
  )
}

export default Cantons
